import React from "react";
import { Link } from "gatsby"

import "../scss/menu.scss"
import closeImg from "../images/close.svg"
const logoDark = require("../images/logo.svg")

class Menu extends React.Component {
    render() {
        return <div className="menu" style={this.props.menuStatus? {top:0}: {}}>
             <Link to="/" className="logo-wrapper">
                <img className="logo" src={logoDark} alt="logo"/>
            </Link>
            <div tabIndex="0" role="button" aria-label="menu close icon" onClick={this.props.handleMenuClick}>
                <img className="close" src={closeImg} alt="close"/>
            </div>
            
            <div className="list">
                {
                    this.props.menu && this.props.menu.map( (e,i)=>{
                        return <div key={i} className="item">
                            <Link to={"/" + e.uri.replace("internal:#","")} onClick={this.props.handleMenuClick}>{e.title}</Link>
                        </div>
                    })
                }
            </div>
        </div>
    }
}

export default Menu;