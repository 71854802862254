import React from "react";
import { Link } from "gatsby"
import "../scss/footer.scss"

class footer extends React.Component {
    render() {
        return <div className="footer">
            <span className="copyright">Copyright © 2020 Exeger</span>
            <ul className="footer-navigation">
                <li><Link to="/privacy">Privacy Policy</Link></li>
                <li><Link to="/terms">Terms &amp; Conditions</Link></li>
            </ul>
        </div>
    }
}

export default footer;