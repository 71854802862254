import React, {Component} from "react"
import { Link } from "gatsby"
import "../scss/header.scss"
const logo = require("../images/logo.svg")
const logoDark = require("../images/logo_dark.svg")

class header extends Component {
  render() {
    return (
    <div className={"header " + (this.props.menuStatus? "hidden" : "") + (this.props.dark? " subpage-header" : "")}>
      <a className="skip-link" href="#main">Skip to main</a>
      {!this.props.menuStatus? 
      <Link to="/">
          <img className="logo" src={this.props.dark? logoDark : logo} alt="logo"/>
      </Link>
      : "" }
      {!this.props.menuStatus? 
      <div tabIndex="0" role="button" aria-label="menu open icon" onClick={this.props.handleMenuClick} className={"menu-icon " + (this.props.dark? "dark":"")}>
        <span />
        <span />
        <span />
      </div> : "" }
    </div>)
  }
}

export default header
